import Scroll from './Scroll';

$(function ($) {
  new Scroll();
  $('.js-menu').on('click', (e) => {
    $('html').toggleClass('is-menu');
  })

  $('.js-slider').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    speed:2000,
    dots: false,
    arrows: false,
    fade:true
  })
});
